import React, { useState, createContext, useContext, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { CSVLink } from 'react-csv'
import { ACCOUNT_ROLES } from '../../constants'
import { FormattedMessage, useIntl } from 'react-intl'
import { Row, Col, Modal, DashboardCard } from '../../components'
import styles from './account-details.module.scss'
import { useQuery, useMutation } from '@apollo/client'
import { queries, mutations } from '../../api'
import { useParams } from '../../router'
import { useAuth } from '../../auth'
import DOMPurify from 'dompurify'
import { Table } from '../../components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCabinetFiling, faTimes, faUsersMedical, faArrowSquareDown } from '@fortawesome/pro-light-svg-icons'
import { faUserPlus, faUserCheck } from '@fortawesome/free-solid-svg-icons'
import { Button } from '../../components'
import { Form, Spinner } from 'react-bootstrap'
import { TextField, SelectField, NumberField } from '../../forms/field'
import Toggle from 'react-toggle'

const AccountContext = createContext({ loading: false, error: [], account: { learners: [] } })

export const AccountDetails = () => {
  const { accountId } = useParams()
  const [{ user }] = useAuth()
  const {
    loading,
    error,
    data: {
      getAccount: {
        id,
        accountUser,
        name,
        notes,
        totalSeats,
        learners: justLearners = [],
        leaders: justLeaders = [],
      } = {},
    } = {},
  } = useQuery(queries.account.getAccount, {
    notifyOnNetworkStatusChange: true,
    variables: {
      accountId,
    },
  })
  const learners = [...justLeaders, ...justLearners]
  const isPrivelagedUser =
    accountUser?.role === ACCOUNT_ROLES.OWNER || accountUser?.role === ACCOUNT_ROLES.LEADER || user?.role === 'ADMIN'
  const availableSeats = Math.max(totalSeats - learners.length, 0)
  const activeLearners = learners.filter((learner) => !learner.accountUser.deletedAt)
  const archivedLearners = learners.filter((learner) => !!learner.accountUser.deletedAt)
  if (loading)
    return <Spinner animation="border" size="sm" variant="light" as="span" role="status" aria-hidden="true" />
  return (
    <AccountContext.Provider
      value={{
        isPrivelagedUser,
        loading,
        error,
        accountId,
        account: { id, name, notes, totalSeats, availableSeats, activeLearners, archivedLearners },
      }}
    >
      <div>
        {/*___account Title___*/}
        <h1>{name}</h1>
        {/*___account details___*/}
        {notes && (
          <>
            <h2>
              <FormattedMessage id="headings.accountDetails" defaultMessage="Account Details" />
            </h2>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(notes) }} />
          </>
        )}
        {/*___seats___*/}
        <h3>
          <FormattedMessage id="headings.learners" defaultMessage="Learners" />
        </h3>
        <Row className={styles.learnerCardContainer}>
          <Col sm={6} md={6} xl={3}>
            <DashboardCard
              title={totalSeats}
              subtitle={<FormattedMessage id="phrase.dashboard.totalSeats" defaultMessage="Total seats purchased" />}
            />
          </Col>
          <Col sm={6} md={6} xl={3}>
            <DashboardCard
              title={activeLearners.length}
              subtitle={<FormattedMessage id="phrase.dashboard.usedSeats" defaultMessage="Learners enrolled" />}
            />
          </Col>
          <Col sm={6} md={6} xl={3}>
            <DashboardCard
              title={archivedLearners.length}
              subtitle={<FormattedMessage id="phrase.dashboard.archivedSeats" defaultMessage="Archived learners" />}
            />
          </Col>
          <Col sm={6} md={6} xl={3}>
            <DashboardCard
              title={availableSeats || 0}
              subtitle={<FormattedMessage id="phrase.dashboard.availableSeats" defaultMessage="Seats Available" />}
            />
          </Col>
        </Row>
        {/*___learners___*/}
        {isPrivelagedUser && <EnrollCtas />}
        <LearnerTable />
        {isPrivelagedUser && <ExportAllButton accountId={accountId} />}
      </div>
    </AccountContext.Provider>
  )
}

const LearnerTable = () => {
  const { loading, error, isPrivelagedUser, account: { archivedLearners, activeLearners } = {} } = useContext(
    AccountContext
  )
  const [learnerToArchive, setLearnerToArchive] = useState(false)
  const [isArchivedView, setIsArcivedView] = useState(false)
  const toggleIsArchivedView = () => setIsArcivedView(!isArchivedView)

  const learners = isArchivedView ? archivedLearners : activeLearners

  const headings = [
    <FormattedMessage id="headings.name" defaultMessage="Name" />,
    <FormattedMessage id="headings.email" defaultMessage="Email" />,
    !isPrivelagedUser || isArchivedView ? null : (
      <>
        <FontAwesomeIcon icon={faCabinetFiling} />
        <span className="sr-only">
          <FormattedMessage id="cta.archive" defaultMessage="Archive" />
        </span>
      </>
    ),
  ]

  const rows = learners.map((learner) => [
    `${learner.firstName} ${learner.lastName}`,
    learner.email,
    !isPrivelagedUser || isArchivedView ? null : (
      <button onClick={() => setLearnerToArchive(learner)}>
        <FontAwesomeIcon icon={faCabinetFiling} />
        <span className="sr-only">
          <FormattedMessage id="cta.archive" defaultMessage="Archive" />
        </span>
      </button>
    ),
  ])

  return (
    <>
      <ArchiveConfirmationModal learner={learnerToArchive} setLearner={setLearnerToArchive} />
      <div className={styles.learnersTableContainer}>
        <div className={styles.archivedViewToggleWrapper}>
          <Toggle defaultChecked={!isArchivedView} onChange={toggleIsArchivedView} id="archived-view__toggle" />
          <label htmlFor="archived_view__toggle">
            {isArchivedView ? (
              <FormattedMessage id="inputs.labels.archived" defaultMessage="Showing Archived Learners" />
            ) : (
              <FormattedMessage id="inputs.labels.active" defaultMessage="Showing Active Learners" />
            )}
          </label>
        </div>
        <Table className={styles.learnersTable} headings={headings} rows={rows} searchable rowsPerPage={10} />
      </div>
    </>
  )
}

const ArchiveConfirmationModal = ({ learner, setLearner }) => {
  const { formatMessage } = useIntl()
  const [error, setError] = useState(false)
  const { accountId } = useContext(AccountContext)
  const [unenrollLearner] = useMutation(mutations.account.unenrollLearner, {
    variables: {
      accountId,
    },
    refetchQueries: [
      {
        query: queries.account.getAccount,
        variables: {
          accountId,
        },
      },
    ],
  })
  const isOpen = !!learner
  const confirm = async () => {
    try {
      await unenrollLearner({
        variables: { email: learner.email },
      })
      close()
    } catch (error) {
      setError('archive', {
        message:
          error?.message ||
          formatMessage({
            id: 'errors.internalServerError',
            defaultMessage: 'There was a problem processing your request, please try again later',
          }),
      })
    }
  }
  const close = async () => {
    setLearner(false)
  }
  return (
    <Modal
      id="enroll-confirmation__modal"
      show={isOpen}
      onHide={close}
      title={
        <h3>
          <FormattedMessage id="cta.archiveLeaner" defaultMessage="Archive Learner" />
        </h3>
      }
      body={
        <p>
          <FormattedMessage
            id="phrase.dashboard.archiveConfirmation"
            defaultMessage="Warning! Once a user is archived it cannot be undone. Archiving a user will remove their access to the account but not reclaim their seat"
          />
        </p>
      }
      footer={
        <>
          {error.message && <span className={styles.errorMessage}>{error?.message}</span>}
          <Button variant="primary" onClick={confirm}>
            <FormattedMessage id="cta.archiveLeaner" defaultMessage="Archive Learner" />
          </Button>
        </>
      }
    />
  )
}

const EnrollCtas = () => {
  const { account: { availableSeats } = {} } = useContext(AccountContext)
  const [isEditing, setIsEditign] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [isRequestSeatsModalOpen, setIsRequestSeatsModalOpen] = useState(false)
  const toggleIsRequestSeatsModalOpen = () => setIsRequestSeatsModalOpen(!isRequestSeatsModalOpen)
  const toggleIsEditing = () => {
    if (availableSeats) {
      setIsConfirmationModalOpen(!isConfirmationModalOpen)
      setIsEditign(!isEditing)
    } else {
      setIsRequestSeatsModalOpen(!isRequestSeatsModalOpen)
    }
  }
  if (!isEditing || !availableSeats)
    return (
      <>
        <RequestSeatsModal isOpen={isRequestSeatsModalOpen} setIsOpen={setIsRequestSeatsModalOpen} />
        <div className={styles.enrollCtasContainer}>
          <Button onClick={toggleIsEditing}>
            <FormattedMessage id="cta.enrollLeaners" defaultMessage="Enroll Learner(s)" />
          </Button>
          <Button variant="unstyled" onClick={toggleIsRequestSeatsModalOpen} className={styles.requestSeats}>
            <FontAwesomeIcon icon={faUsersMedical} />
            <FormattedMessage id="cta.requestSeats" defaultMessage="Request More Seats" />
          </Button>
        </div>
      </>
    )
  return (
    <div className={styles.enrollFormContainer}>
      <ConfirmationModal isOpen={isConfirmationModalOpen} setIsOpen={setIsConfirmationModalOpen} />
      <div className={styles.enrollLearnerFormWrapper}>
        <button size="small" onClick={toggleIsEditing}>
          <FontAwesomeIcon icon={faTimes} size="2x" />
          <span className="sr-only">
            <FormattedMessage id="close" defaultMessage="Close" />
          </span>
        </button>
        <h3>
          <FormattedMessage id="cta.enrollLeaners" defaultMessage="Enroll Learner(s)" />
        </h3>
        <EnrollLearnerForm />
      </div>
    </div>
  )
}

const RequestSeatsModal = ({ isOpen, setIsOpen }) => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const close = () => {
    setIsOpen(false)
    setTimeout(() => setIsSubmitted(false), 1000)
  }
  const confirmSubmitted = () => {
    setIsSubmitted(true)
  }
  return (
    <Modal
      id="request-seats__modal"
      className={styles.requestAdditionalSeatsModal}
      show={isOpen}
      onHide={close}
      title={
        isSubmitted ? (
          <h3>
            <FormattedMessage id="heading.requestSeatsRecieved" defaultMessage="We have recieved your request" />
          </h3>
        ) : (
          <h3>
            <FormattedMessage id="headings.requestSeats" defaultMessage="Request Additional Seats" />
          </h3>
        )
      }
      body={
        isSubmitted ? (
          <p>
            <FormattedMessage
              id="phrase.dashboard.requestSeatsSubmitted"
              defaultMessage="Our team will connect with you within 2 business day"
            />
          </p>
        ) : (
          <RequestSeatsForm confirmSubmitted={confirmSubmitted} />
        )
      }
      footer={
        isSubmitted && (
          <Button variant="primary" onClick={close}>
            <FormattedMessage id="close" defaultMessage="Close" />
          </Button>
        )
      }
    />
  )
}

const RequestSeatsForm = ({ confirmSubmitted }) => {
  const { formatMessage } = useIntl()
  const {
    account: { id, name, totalSeats },
  } = useContext(AccountContext)
  const account = { id, name, totalSeats }
  const [requestAdditionalSeats] = useMutation(mutations.users.requestAdditionalSeats)
  const {
    register: registerField,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {},
    shouldFocusError: true,
    shouldUnregister: true,
    mode: 'onSubmit',
  })

  const onSubmit = async ({ seats }) => {
    try {
      const seatsInt = new Number(seats)
      if (!isNaN(seatsInt))
        await requestAdditionalSeats({
          variables: {
            seats: seatsInt,
            account,
          },
        })
      reset()
      confirmSubmitted()
    } catch (error) {
      setError('submit', {
        message:
          error?.message ||
          formatMessage({
            id: 'errors.internalServerError',
            defaultMessage: 'There was a problem processing your request, please try again later',
          }),
      })
    }
  }
  if (isSubmitting)
    return <Spinner animation="border" size="sm" variant="light" as="span" role="status" aria-hidden="true" />

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className={styles.requestAdditionalSeatsForm}>
      <p>
        <FormattedMessage
          id="phrase.dashboard.requestSeats"
          defaultMessage="Please enter the number of seats you'd like to add"
        />
      </p>
      <NumberField
        id="seats"
        name="seats"
        testId="seats"
        required={true}
        label={<FormattedMessage id="inputs.labels.additionalSeats" defaultMessage="Additional Seats" />}
        fieldRef={registerField}
        errors={errors.seats}
        min={1}
        max={1000}
      />
      {errors?.submit && <span className={styles.errorMessage}>{errors?.submit?.message}</span>}
      <Button type="submit" disabled={isSubmitting} onClick={() => errors.submit && clearErrors('submit')}>
        <FormattedMessage id="cta.submit" defaultMessage="Submit" />
      </Button>
    </Form>
  )
}

const ConfirmationModal = ({ isOpen, setIsOpen }) => {
  const [isViewed, setIsViewed] = useState(false)
  const close = () => {
    setIsViewed(true)
    setIsOpen(!isOpen)
  }
  if (isViewed) return null

  return (
    <Modal
      id="enroll-confirmation__modal"
      show={isOpen}
      onHide={close}
      title={
        <h3>
          <FormattedMessage id="cta.enrollLeaners" defaultMessage="Enroll Learner(s)" />
        </h3>
      }
      body={
        <p>
          <FormattedMessage id="phrase.dashboard.enrollConfirmation" defaultMessage="Enroll Learner(s)" />
        </p>
      }
      footer={
        <Button variant="primary" onClick={close}>
          <FormattedMessage id="cta.understood" defaultMessage="I Understand" />
        </Button>
      }
    />
  )
}

const EnrollLearnerForm = () => {
  const { accountId } = useParams()
  const { formatMessage } = useIntl()
  const [enrollLearner] = useMutation(mutations.account.enrollLearner, {
    variables: {
      accountId,
    },
    refetchQueries: [
      {
        query: queries.account.getAccount,
        variables: {
          accountId,
        },
      },
    ],
  })
  const [isSuccessfullyEnrolled, setIsSuccessfullyEnrolled] = useState(false)
  const {
    register: registerField,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    reset,
    formState: { isDirty } = {},
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {},
    shouldFocusError: true,
    shouldUnregister: true,
    mode: 'onSubmit',
  })
  const [isAdded, setIsAdded] = useState(false)
  const confirmAdded = (firstName) => {
    setIsAdded(true)
    setIsSuccessfullyEnrolled(firstName)
    setTimeout(() => setIsAdded(false), 1000)
  }

  const onSubmit = async ({ firstName, lastName, email, locale }) => {
    try {
      await enrollLearner({
        variables: {
          firstName,
          lastName,
          email,
          locale,
        },
      })
      reset()
      confirmAdded(firstName)
    } catch (error) {
      if (error?.message.includes('Validation isEmail on email failed'))
        setError('enroll', {
          message: formatMessage({
            id: 'errors.invalidEmailFormat',
            defaultMessage: 'Invalid email format',
          }),
        })
      else if (error?.message.includes('Learner is already enrolled to this account'))
        setError('enroll', {
          message: formatMessage({
            id: 'errors.learnerEnrolled',
            defaultMessage: 'Learner is already enrolled',
          }),
        })
      else {
        setError('enroll', {
          message:
            error?.message ||
            formatMessage({
              id: 'errors.internalServerError',
              defaultMessage: 'There was a problem processing your request, please try again later',
            }),
        })
      }
    }
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)} className={styles.enrollLearnerForm}>
      <TextField
        id="firstName"
        name="firstName"
        testId="firstName"
        required={true}
        label={<FormattedMessage id="inputs.labels.firstName" defaultMessage="First Name" />}
        fieldRef={registerField}
        errors={errors.firstName}
      />
      <TextField
        className={styles.lastName}
        id="lastName"
        name="lastName"
        testId="lastName"
        required={true}
        label={<FormattedMessage id="inputs.labels.lastName" defaultMessage="Last Name" />}
        fieldRef={registerField}
        errors={errors.lastName}
      />
      <TextField
        className={styles.email}
        id="email"
        name="email"
        testId="email"
        label={<FormattedMessage id="inputs.labels.workEmail" defaultMessage="Work Email" />}
        fieldRef={registerField}
        errors={errors.email}
      />
      <SelectField
        className={styles.locale}
        id="locale"
        name="locale"
        label={<FormattedMessage id="inputs.labels.locale" defaultMessage="Locale" />}
        required={true}
        errors={errors.locale}
        testId="locale"
        fieldRef={registerField}
        options={[
          { value: 'en', label: 'EN' },
          { value: 'fr', label: 'FR' },
        ]}
      />
      {!isDirty && isSuccessfullyEnrolled && (
        <span className={styles.successMessage}>
          {isSuccessfullyEnrolled}{' '}
          <FormattedMessage id="phrase.dashboard.enrollSuccessful" defaultMessage="was sucessfully enrolled" />{' '}
          <FontAwesomeIcon icon={faUserCheck} />
        </span>
      )}
      {errors?.enroll && <span className={styles.errorMessage}>{errors?.enroll?.message}</span>}
      <Button type="submit" onClick={() => errors.enroll && clearErrors('enroll')}>
        <FormattedMessage id="cta.enroll" defaultMessage="Enroll Now" />
        {isAdded ? <FontAwesomeIcon icon={faUserCheck} /> : <FontAwesomeIcon icon={faUserPlus} />}
      </Button>
    </Form>
  )
}

const ExportAllButton = ({ accountId }) => {
  const [exportData, setExportData] = useState()
  const csvRef = useRef(null)
  // Gross hack - use refetch to since useLazyQuery does not return a promise
  const { refetch } = useQuery(queries.account.exportAccountData, {
    skip: true,
    variables: {
      accountId,
    },
  })

  const getExportData = () => {
    refetch().then((data) => {
      const rawData = data?.data?.exportAccountData || {}
      const formattedData = formatExportData(rawData)
      setExportData(formattedData)
    })
  }

  useEffect(() => {
    // Click the CSVLink component to trigger the CSV download
    if (exportData && csvRef.current && csvRef.current.link) {
      setTimeout(() => {
        csvRef.current.link.click()
        setExportData()
      })
    }
  }, [exportData])

  return (
    <>
      <Button variant="unstyled" onClick={getExportData} className={styles.exportAllButton}>
        <FontAwesomeIcon icon={faArrowSquareDown} />
        <FormattedMessage id="phrase.dashboard.exportAll" defaultMessage="Export All Account Info" />
      </Button>
      {exportData && <CSVLink ref={csvRef} data={exportData} filename="all-account-info.csv" target="_self" />}
    </>
  )
}

const formatExportData = ({ programs = [], learners = [], completions = [] }) => {
  const rows = [['Email', 'First Name', 'Last Name', 'Active/Archived']]
  // Add heading rows for each program
  programs.map((program) => {
    if (program.preAssessment) {
      rows[0].push(`${program.name} Pre-Assessment`)
    }
    rows[0].push(`${program.name} Certificate`)
  })
  // Add row for each learner
  learners.map((learner) => {
    const archived = learner.accountUser.deletedAt
    const row = [learner.email, learner.firstName, learner.lastName, archived ? 'Archived' : 'Active']
    // Find relevant completions for each program and add to row
    programs.map((program) => {
      const completion =
        completions.find((completion) => completion.userId === learner.id && completion.programId === program.id) || {}
      if (program.preAssessment) {
        row.push(completion.preAssessmentComplete ? true : false)
      }
      row.push(completion.programComplete ? true : false)
    })
    rows.push(row)
  })
  return rows
}
